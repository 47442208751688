import React, { useEffect } from "react";
import Header from "../Home/Navbar";
import Col from "react-bootstrap/Col";
import { Container, Row } from "react-bootstrap";
import Footer from "../Home/Footer";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export default function Contact() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <Header />

      <div>
        <Container className="service-banner-1">
          {/* ROW-1 */}
          <Row className="contact-banner py-4">
            <Col>
              <p className="head">Contact Us</p>

              <p>
                We'd love to hear from you! Whether you have questions about our
                products, need assistance with your order, or want to discuss a
                custom design, our team is here to help.
              </p>

              <div className="d-flex" style={{ gap: "10px" }}>
                <div>
                  <MdEmail />
                </div>
                <p className="footer-7-txt-3">support@sarastockdesigns.com</p>
              </div>

              <div className="d-flex" style={{ gap: "10px" }}>
                <div>
                  <FaPhoneAlt />
                </div>
                <p className="footer-7-txt-3">(123) 456-7890</p>
              </div>
            </Col>
            <Col>
              <div>
                <img
                  className="service-img"
                  src="/assets/Rectangle 236 (2).png"
                  alt="img"
                />
              </div>
            </Col>
          </Row>

          <p className="contact-txt">
            Feel free to reach out, and we'll get back to you as soon as
            possible. Thank you for choosing Sara Stock Designs!
          </p>
        </Container>

        <Footer />
      </div>
    </div>
  );
}
