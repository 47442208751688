import React, { useEffect } from "react";
import Header from "../Home/Navbar";
import Col from "react-bootstrap/Col";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Footer from "../Home/Footer";

export default function Service() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <Header />

      <div className="py-4">
        <Container className="service-banner-1 ">
          {/* ROW-1 */}
          <Row className="service-row py-3">
            <Col sm={12} md={6} lg={6}>
              <p className="head">Embroidering design</p>
              <p className="service-txt">About Our Embroidering design</p>
              <p>
                At Sara Stock Designs, we take pride in offering a diverse range
                of embroidery designs that cater to various tastes and
                preferences. Our designs are meticulously crafted to ensure
                quality and detail, making them perfect for all your embroidery
                projects
              </p>

              <p className="service-txt-1">Types of Designs:</p>
              <p className="service-txt-2">Seasonal and Holiday Themes:</p>
              <p>
                Celebrate every season and holiday with our exclusive embroidery
                designs. From festive Christmas patterns to spooky Halloween
                motifs, we have something for every occasion.
              </p>
              <p className="service-txt-2">Inspirational Quotes and Sayings:</p>
              <p>
                Add a touch of inspiration to your projects with our beautifully
                designed quotes and sayings. These designs are perfect for
                gifts, home decor, and personal items
              </p>

              <p className="service-txt-2">Sports and Hobbies:</p>
              <p>
                Show your passion for sports and hobbies with our specialized
                designs. From football and basketball to sewing and gardening,
                we offer a wide range of themes.
              </p>
            </Col>
            <Col  sm={12} md={6} lg={6}>
              <div>
                <img
                  className="service-img"
                  src="/assets/Rectangle 236.png"
                  alt="img"
                />
              </div>

              <div style={{ marginTop: "30px" }}>
                <p className="service-txt-1">Features:</p>
                <p className="service-txt-2">Multiple Formats:</p>
                <p>
                  Our designs are available in various formats, including EXP,
                  HUS, PES, DST, SEW, JEF, PCS, VP3, XXX, PDF. ensuring
                  compatibility with most embroidery machines.
                </p>
                <p className="service-txt-2">High-Quality and Detailed:</p>
                <p>
                  Each design is created with attention to detail, ensuring
                  high-quality results that stand out.
                </p>

                <p className="service-txt-2">User-Friendly:</p>
                <p>
                  Easy-to-download and use, our designs are perfect for both
                  beginners and experienced embroiderers.
                </p>
                <Button className="service-but">Explore Now</Button>
              </div>
            </Col>
          </Row>

          {/* ROW-2 */}
          <Row className="service-row py-3">
            <Col sm={12} md={6} lg={6}>
              <div >
                <img
                  className="service-img"
                  src="/assets/Mask group (2).png"
                  alt="img"
                />
              </div>

              <div style={{ marginTop: "30px" }}>
                <p className="service-txt-1">Types of Designs:</p>
                <p className="service-txt-2">Floral and Nature</p>
                <p>
                  Beautiful floral patterns and nature-inspired designs add a
                  touch of elegance and serenity to any project.
                </p>
                <p className="service-txt-2">Seasonal and Holiday Themes</p>
                <p>
                  Celebrate holidays and seasons with our themed designs,
                  perfect for decorations and gifts.
                </p>

                <p className="service-txt-2">Inspirational Quotes</p>
                <p>
                  Uplift and inspire with our collection of quote designs, ideal
                  for home decor and personal items.
                </p>
              </div>
            </Col>

            <Col sm={12} md={6} lg={6}>
              <p className="head">Print design</p>
              <p className="service-txt">About Our Print design</p>
              <p>
                At Sara Stock Designs, we offer an array of print designs
                perfect for enhancing your projects. Our print designs are
                versatile, high-quality, and created with artistic flair to meet
                various needs and preferences.
              </p>
              <p className="service-txt-1">Features:</p>
              <p className="service-txt-2">Multiple Formats:</p>
              <p>
                Available in various digital formats, ensuring compatibility
                with your printing needs.
              </p>
              <p className="service-txt-2">High-Resolution:</p>
              <p>
                Our designs are crafted in SVG & PNG high resolution,
                guaranteeing crisp and clear prints every time.
              </p>

              <p className="service-txt-2">High-Resolution:</p>
              <p>
                Download and integrate our print designs effortlessly into your
                projects.
              </p>
              <Button className="service-but">Explore Now</Button>
            </Col>
          </Row>

          {/* ROW-3 */}
          <Row className="service-row py-3">
            <Col sm={12} md={6} lg={6}>
              <p className="head">Vector Tracing</p>
              <p className="service-txt">About Our Vector Tracing</p>
              <p>
                Transform your rasterized images into high-quality vector
                graphics effortlessly. Our expert team is here to provide you
                with crisp, scalable images that meet your exact needs. Here's
                how our service works:
              </p>

              <p className="service-txt-2">How It Works</p>
              <p>
                Upload Your Image: Send us your rasterized image (JPEG, PNG, AI,
                EPS, SVG) through our easy-to-use upload system.
              </p>
              <p>
                Specify Your Requirements: Let us know the details of your
                project. Specify the format you need (AI, EPS, SVG, PDF, etc.),
                any particular details or requirements, and your desired
                turnaround time.
              </p>
              <p>
                We Get to Work: Our skilled designers will meticulously trace
                your image to create a clean, high-quality vector file. We
                ensure every detail is captured with precision.
              </p>
              <p>
                Receive Your Vector Image: After your approval, well send you
                the final vector image in your specified format, ready for use
                in any application.
              </p>

              <Button className="service-but">Explore Now</Button>
            </Col>
            <Col sm={12} md={6} lg={6} className="d-flex justify-content-center align-items-center">
              <div className="service-banner-3">
                <img
                  className="service-img"
                  src="/assets/Group 972.png"
                  alt="img"
                />
              </div>
            </Col>
          </Row>

          {/* ROW-4 */}
          <Row className="service-row pt-3 pb-5">
            <Col sm={12} md={6} lg={6} className=" d-flex justify-content-center align-items-center">
              <div className="service-banner-3">
                <img
                  className="service-img img-fluid"
                  src="/assets/Rectangle 242.png"
                  alt="img"
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <p className="head">Custom Disgitizing</p>
              <p>
                Sara Designs converts Digitizing the images, clip arts, arts
                into machine embroidery files available for instant download in
                multiple formats like DST, HUS, JEF, PES, XXX, VP3, VIP, EXP.
                For those seeking something unique, we offer a customization
                service. Simply submit your design and size specifications using
                our online form, and we'll review your request and send you a
                quote. Once you approve the quote and make the payment, our team
                will craft your custom embroidery design and deliver it to you
                in the specified digital format. Enjoy seamless access to
                beautiful embroidery designs tailored to your needs.
              </p>

              <Button className="service-but">Explore Now</Button>
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    </div>
  );
}
