import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaPhone } from "react-icons/fa6";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import { IoHome } from "react-icons/io5";

const drawerWidth = 240;

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "start" }}>
      <List className="nav-4">
        <Link to="/" className="">
          <img
            src="/assets/sara-logo.png"
            alt="logo"
            className="img-fluid pb-2"
            style={{ width: "50px" }}
          />
        </Link>
        <Link to="/" className="head-nav nav-link-with-line py-3">
          Home
        </Link>
        <Link to="/service" className="head-nav nav-link-with-line py-3">
          Service
        </Link>
        <Link to="/contact" className="head-nav nav-link-with-line py-3">
          Contact
        </Link>
        <p className=" footer-txt">Follow us on:</p>
        <div className="footer-img-combine">
          <div>
            <a href="https://www.facebook.com/saradesigns09" target="_blank">
            <img className="footer-img" src="/assets/facebook.png" alt="img" />
            </a>
          </div>
          <div>
            <a target="_blank" href="https://www.instagram.com/saradesigns09/">
            <img className="footer-img" src="/assets/instagram.png" alt="img" />
            </a>
          </div>
          <div>
            <a target="_blank" href="https://in.pinterest.com/embroideryartsstore/">
            <img className="footer-img" src="/assets/social.png" alt="img" />
            </a>
          </div>
        </div>
      </List>
    </Box>
  );

  return (
    <>
      <section className="header-margin">
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar component="nav">
            <Container>
              <Toolbar className="nav">
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    display: { xs: "none", sm: "block", flexGrow: "1" },
                  }}
                >
                  <div className="logo">
                    <Link to="/">
                      <img
                        className="img-fluid header-logo"
                        src="/assets/sara-logo.png"
                        alt="Logo"
                      />
                    </Link>
                  </div>
                </Typography>
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                >
                  <div className="nav-1">
                    <div className="nav-2">
                      <Link to="/" className="nav-center head-nav">
                        Home
                      </Link>

                      <Link to="/service" className="nav-center head-nav mx-4">
                        Service
                      </Link>
                      <Link to="/contact" className="nav-center head-nav">
                        Contact
                      </Link>
                    </div>
                    <div className="head-nav-2">
                      <div className="nav-icon">
                        <FaPhone />
                      </div>
                      <span className="contact-a"><a href="tel:+91 7299994944" style={{color:"#000",fontWeight:500}}>+91 7299994944</a></span>
                    </div>
                  </div>
                </Box>
              </Toolbar>
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-between align-items-center d-md-none"
              >
                <div className="logo">
                  <Link to="/">
                    <img
                      className="img-fluid header-logo"
                      src="/assets/sara-logo.png"
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { md: "none" } }}
                  >
                    <MenuIcon />
                  </IconButton>
                </div>
              </div>
            </Container>
          </AppBar>

          <nav>
            <div className="nav-3">
              <Drawer
                // container={Container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>
            </div>
          </nav>
        </Box>
      </section>
    </>
  );
};

export default Header;
