import React from "react";
import { Container, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Input } from "antd";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section className="footer py-4">
        <Container>
          <Row>
            <Col lg={4} md={4} sm={12} className="pt-2">
              <div>
                <div className="logo">
                  <Link to={"/"}>
                  <img
                    className="img-fluid header-logo"
                    src="/assets/sara-logo.png"
                    alt="Logo"
                  /></Link>
                </div>
                <p className="footer-7-txt-3 me-5">Our skilled artisans bring your vision to life with precision craftsmanship, ensuring every piece is a true reflection of your unique style.</p>
                <p className="footer-txt">Follow Us On:</p>
                <div className="footer-img-combine">
                  <div>
                    <a href="https://www.facebook.com/saradesigns09" target="_blank">
                    <img
                      className="footer-img"
                      src="/assets/facebook.png"
                      alt="img"
                    />
                    </a>
                  </div>
                  <div>
                  <a href="https://www.instagram.com/saradesigns09/" target="_blank">
                    <img
                      className="footer-img"
                      src="/assets/instagram.png"
                      alt="img"
                    />
                    </a>
                  </div>
                  <div>
                  <a href="https://in.pinterest.com/embroideryartsstore/" target="_blank">
                    <img
                      className="footer-img"
                      src="/assets/social.png"
                      alt="img"
                    />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} className="pt-2">
              <p className="footer-7-txt-2">Contact Us</p>
              {/* <div className="d-flex" style={{ gap: "10px" }}>
                <div>
                  <IoLocationSharp />
                </div>
                <p className="footer-7-txt-3 mb-2">
                  123, Mail cross street, City -600 000
                </p>
              </div> */}
              <p className="footer-7-txt-3 me-5">Feel free to reach out, and we'll get back to you as soon as possible. Thank you for choosing Sara Stock Designs!

</p>
              <div className="d-flex" style={{ gap: "10px" }}>
                <div>
                  <FaPhoneAlt />
                </div>
                <a className="footer-7-txt-3 mb-2" href="tel:+91 7299994944">+91 7299994944</a>
              </div>
              <div className="d-flex" style={{ gap: "10px" }}>
                <div>
                  <MdEmail />
                </div>
                <a className="footer-7-txt-3" href="mailto:sara@saradigitizing.com">sara@saradigitizing.com</a>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} className="pt-2">
              <p className="footer-7-txt-2">SUBSCRIBE</p>
              <p className="footer-7-txt-3">
                To get discount and coupon card updates
              </p>
              <div className="footer-input-combine">
                <div>
                  <Input
                    className="footer-input"
                    placeholder="Enter Your Email id"
                  />
                </div>
                <div>
                  <Button type="submit" className="footer-but">
                    Submit
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
