import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled, { css } from "styled-components";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { Flex, Rate } from "antd";
import { FaCartShopping } from "react-icons/fa6";
import Header from "./Navbar";
import Footer from "./Footer";

const desc = ["terrible", "bad", "normal", "good", "wonderful"];

const CarouselContainer = styled.div`
  .slick-slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.5s ease;
  }

  .slick-center img {
    transform: scale(1); /* Normal size for the centered slide */
  }

  .slick-slide:not(.slick-center) img {
    transform: scale(1.1); /* Scale up non-centered slides */
    border: 5px solid #fff;
    border-radius: 10px;
    overflow: hidden;
  }

  .slick-slide {
    opacity: 1;
  }

  .slick-center {
    opacity: 1;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    font-size: 24px;
    color: #000;
  }

  .slick-prev {
    left: 10px;
    display: none;
  }

  .slick-next {
    right: 10px;
    display: none;
  }

  .slick-track {
    left: 2px;
  }
`;

const Banner = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [currentSlideM, setCurrentSlideM] = useState(0);

  const [currentSlides, setCurrentSlides] = useState(0);

  const [value, setValue] = useState(5);

  const [currentBigIndex, setCurrentBigIndex] = useState(-1);

  // const [smallImages] = useState<string[]>([
  //   // Add your image URLs here
  //   "/assets/handbag.png",
  //   "/assets/t-shirt-1.png",
  //   "/assets/Group 24.png",
  //   "/assets/Group 28.png",
  // ]);



  const [imagesAni, setImagesAni] = useState([
    "/assets/handbag.png",
    "/assets/t-shirt-1.png",
    "/assets/Group 24.png",
    "/assets/Group 28.png",
  ]);






  const [currentSmallIndex, setCurrentSmallIndex] = useState<number>(1);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // Move the current small image to the big div
  //     if (currentSmallIndex < smallImages.length) {
  //       setCurrentBigIndex(currentSmallIndex);
  //       setCurrentSmallIndex((prevIndex) => prevIndex + 1);
  //     } else {
  //       // Reset to start over
  //       setCurrentSmallIndex(0);
  //       setCurrentBigIndex(1);
  //     }
  //   }, 1000); // 1-second interval between movements

  //   return () => clearInterval(interval);
  // }, [currentSmallIndex, smallImages.length]);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleBeforeChange = (oldIndex: number, newIndex: number) => {
    setActiveIndex(newIndex);
  };

  const images = [
    { src1: "/assets/design-2.jpg", src2: "/assets/design-3.jpg" },
    { src1: "/assets/design-6.jpg", src2: "/assets/design-7.jpg" },
    { src1: "/assets/design-1.jpg", src2: "/assets/design-9.jpg" },
    { src1: "/assets/design-5.png", src2: "/assets/design-8.jpg" },
  ];

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 2,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current: any, next: any) => setCurrentSlideM(next),
  };

  const settingM = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    dots: true,
    appendDots: (dots: any) => (
      <div
        style={{
          position: "absolute",
          width: "500px",
          right: "130%",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current: any, next: any) => setCurrentSlides(next),
  };

  // Animation-1

  const [isAniData,setisAniData]=useState([
    {
      head1: "Print design",
      para: "Bringing Your Ideas to Life in Print",
      para1:
        "At Sara stock designs, we specialize in creating eye-catching and professional print designs that make a lasting impression. From business cards and brochures to posters and packaging, our talented designers transform your concepts into stunning printed materials. Explore our portfolio or get in touch to start your custom design project today.",
    },
    {
      head1: "Embroidery design",
      para: "Transforming Threads into Art",
      para1:
        "At Sara stock designs, we specialize in creating stunning embroidery designs that add a touch of elegance and personality to your fabrics. Whether you’re looking to personalize apparel, accessories, or home décor, our expert designers are here to bring your vision to life with meticulous attention to detail. Explore our collection or request a custom design today and elevate your style with exquisite embroidery.",
    },
    {
      head1: "Custom digitizing",
      para: "Precision Digitizing for Your Unique Designs",
      para1:
        "At Sara stock designs, we transform your artwork into high-quality, ready-to-use digital embroidery files with meticulous attention to detail. Our custom digitizing services ensure that your designs are accurately represented, with crisp lines and flawless stitching. Whether you need digitizing for logos, intricate patterns, or any unique design, our expert team is here to deliver exceptional results. Get started with our seamless process and bring your creations to life in embroidery.",
    },
    {
      head1: "Vector tracing",
      para: "Transform Your Images into Crisp, Scalable Vector Art",
      para1:
        "At  Sara stock designs, we specialize in converting your raster images into high-quality vector files. Our vector tracing services ensure that your designs are scalable without losing detail or clarity, making them perfect for logos, illustrations, and print materials. Whether you need a simple logo trace or a complex illustration conversion, our skilled team delivers precise and accurate results every time. Elevate your designs with flawless vector art and see the difference.",
    },
  ])

  // Animation-2

  const[datas,setDatas]=useState([
    {
      head: "Our Service ",
      para1: "Embroidery Design",
      para2:
        "We specialize in creating stunning embroidery designs that add a touch of elegance and professionalism to your appareland. ",
    },
    {
      head: "Our Service ",
      para1: "Print Design",
      para2:
        "We offer top-notch screen printing design services that bring your creative ideas to life with vibrant, durable prints. ",
    },
    {
      head: "Our Service",
      para1: "Custom Digitization",
      para2:
        "We offer expert custom digitizing services to convert your images into stunning embroidery designs. ",
    },
    {
      head: "Our Service",
      para1: "Vector Art",
      para2:
        "We offer professional vector art services to create clean, scalable graphics for your design needs. ",
    }])




  
  useEffect(() => {
    const intervalId = setInterval(() => {
        setImagesAni((prevImages) => {
            const updatedImages = [...prevImages];
            const update = updatedImages.shift();
            updatedImages.push(update ? update : "");
            return updatedImages;
        });

        setDatas((prevDatas) => {
          const updatedDatas = [...prevDatas];
          const updatePara = updatedDatas.shift();
          if (updatePara) {
              updatedDatas.push(updatePara); 
          }
          return updatedDatas;
      });
      // Update isAniData
    setisAniData((prevDatas) => {
      const updatedDatas = [...prevDatas];
      const updatePara = updatedDatas.shift();
      if (updatePara) {
        updatedDatas.push(updatePara);
      }
      return updatedDatas;
    });
    }, 3000);

    return () => clearInterval(intervalId);
}, []);

  return (
    <>
      <Header />

      <section className="banner">
        <Container>
          <div className="banner-combine">
            <div
              className="col-lg-7 col-md-6 col-sm-12 banner-change" 
              style={{ height: "100%" }}
            >
              <div className="banner-con">
                <p className="head-1">{isAniData[0].head1}</p>
                <p className="home-txt-1">{isAniData[0].para}</p>
                <p className="home-txt-2">{isAniData[0].para1}</p>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 sm-banner banner-carsoul">
              <div>
              <Slider {...settings} beforeChange={handleBeforeChange}>
                {images.map((image, index) => (
                  <div
                    key={index}
                    className={`p-3 animation-1 ani-1 ${
                      index === activeIndex ? "active-slide" : "inactive-slide"
                    }`}
                  >
                    <div>
                      <img
                        className={`img-fluid-1 ${
                          index === activeIndex ? "active-img" : ""
                        }`}
                        src={image.src1}
                        alt={`Slide ${index + 1}`}
                      />
                    </div>
                    <div className="ani-side">
                      <img
                        src={image.src2}
                        className={`img-fluid-2 ${
                          index === activeIndex ? "active-img" : ""
                        }`}
                        alt="side"
                      />
                    </div>
                  </div>
                ))}
              </Slider>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="py-3">
        <Container className="">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="">
                <p className="txt-1 blue">About us</p>
                <p className="home-txt-1">Welcome to Sara Stock Designs</p>
                <p className="banner-1-pre">
                  At Sara Stock Designs, we bring your visions to life with
                  precision, creativity, and a personal touch. As a leading
                  provider of print design, embroidery design, custom
                  digitizing, and vector art, we cater to a diverse range of
                  needs, ensuring every project is executed with the utmost
                  quality and attention to detail.
                </p>
                <p>
                  Our journey began with a simple mission: to deliver
                  outstanding design solutions that exceed our clients'
                  expectations. Over the years, we've honed our craft, embracing
                  the latest technologies and trends to stay ahead in the
                  dynamic world of design and embroidery.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <div>
             <img src="/assets/Group 2.png" className="img-fluid img-c" alt="img"/>
             </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="banner-2 py-5">
        <Container>
          <div className="row">
          <div className="banner-2-ani col-lg-3 col-md-0 col-xs-0 col-sm-0 d-sm-none d-lg-block sm-hide">
            <div className="big-div">
              <div className="content-wrapper">
                <div className="large-image">
                    <img
                      src={imagesAni[3]}
                      alt="Large Display"
                    />
                </div>
              </div>
            </div>
            </div>

            <div className="banner-2-combine col-lg-9 col-md-12 col-sm-12">
              <div
                className="row"
              >
                <div className="col-lg-4 col-md-8 col-sm-12">
                  <p className="blue">{datas[0].head} </p>
                  <p className="home-txt-1">{datas[0].para1}</p>
                </div>

                <div  className="col-lg-8 col-md-8 col-sm-12">
                  <p className="home-txt-2">{datas[0].para2}</p>
                  <button className="banner-2-but">View More &gt; &gt; </button>
                </div>
              </div>

              <div className="small-divs row justify-content-between align-items-center">
                {imagesAni.slice(0,3).map((image, index) => (
                  <div className="small-divX col-lg-3 col-md-3 col-sm-12">
                    <div>
                      <img
                        src={image}
                        key={index}
                        className="small-div"
                        alt={`Small Display ${index + 1}`}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
      
        </Container>
      </section>

      <section className="banner-3">
        <div className="refferal">
          <img
            src="/assets/refferal-1.jpg"
            className="refferal-img img-fluid d-sm-none d-md-block"
            alt="img"
          />
            <img
            src="/assets/Group 970.png"
            className="refferal-img img-fluid d-sm-block d-md-none"
            alt="img"
          />
        </div>
      </section>

      <section className="banner-4 py-5">
        <Container>
          <Row className="banner-4-content">
            <Col xs={12} sm={12} md={6} lg={3} className="">
              <div className="p-1">
                <p className="blue">Portfolio</p>
                <p className="home-txt-1">Explore Our Stunning Creations</p>
                <p>
                  We take pride in our work and are excited to showcase our
                  diverse range of works.
                </p>
                <Button className="banner-4-but">Shop Now</Button>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} className="banner-4-col">
              <div className="p-1">
                <img src="/assets/Group 973.png" alt="bag" />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} className="banner-4-col">
              <div className="p-1">
                <img src="/assets/Group 974.png" alt="bag" />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} className="banner-4-col">
              <div className="p-1">
                <img src="/assets/Group 975.png" alt="bag" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="banner-5 py-5">
        <Container>
          <Row className="banner-5-row">
            <Col xs={12} sm={12} md={12} lg={6}>
              <div>
                <p className="blue">Why Choose Us</p>
                <p className="home-txt-1">Why Trust Us with Your Designs</p>
                <Row className="py-3">
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div>
                      <img
                        src="/assets/icon-1.png"
                        className="banner-icon"
                        alt="icon"
                      />
                      <p className="banner-5-txt">Expertise</p>
                      <p className="banner-5-txt-1">
                        With years of experience in the industry, we possess the
                        knowledge and skills to handle a wide range of design
                        and embroidery projects.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div>
                      <img
                        src="/assets/icon-2.png"
                        className="banner-icon"
                        alt="icon"
                      />
                      <p className="banner-5-txt">Customization</p>
                      <p className="banner-5-txt-1">
                        We understand that each project is unique, and we offer
                        alter solutions to meet your specific needs.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div>
                      <img
                        src="/assets/icon-3.png"
                        className="banner-icon"
                        alt="icon"
                      />
                      <p className="banner-5-txt">Quality</p>
                      <p className="banner-5-txt-1">
                        Our commitment to quality is fixed . We use the best
                        materials and latest techniques to deliver outstanding
                        results.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div>
                      <img
                        src="/assets/icon-4.png"
                        className="banner-icon"
                        alt="icon"
                      />
                      <p className="banner-5-txt">Customer Satisfaction</p>
                      <p className="banner-5-txt-1">
                        Your satisfaction is our top priority. We work closely
                        with you throughout the process to ensure your vision is
                        realized.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/assets/embrodiery.png"
                  className="banner-5-img"
                  alt="img"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="banner-6 py-5">
        <Container>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div>
                <p className="blue">Testimonial </p>
                <p className="home-txt-1">Hear from Our Happy Clients</p>
                <p>
                  We take pride in delivering exceptional quality and service.
                  Don't just take our word for it see what our satisfied
                  customers have to say about their experiences working with us.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 animation-2-combine">
            <div className="">
              <CarouselContainer>
                <Slider {...settingM}>
                  <div className="p-3 animation-2">
                    <img
                      className="img-fluid-1-ani"
                      src="/assets/Property 1=Frame 5.png"
                      alt="Slide 1"
                    />
                  </div>
                  <div className="p-3 animation-2">
                    <img
                      className="img-fluid-1-ani"
                      src="/assets/Property 1=Frame 6.png"
                      alt="Slide 2"
                    />
                  </div>
                  <div className="p-3 animation-2">
                    <img
                      className="img-fluid-1-ani"
                      src="/assets/Property 1=Frame 7.png"
                      alt="Slide 3"
                    />
                  </div>
                  <div className="p-3 animation-2">
                    <img
                      className="img-fluid-1-ani"
                      src="/assets/Property 1=Frame 6.png"
                      alt="Slide 2"
                    />
                  </div>
                </Slider>
              </CarouselContainer>
            </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="banner-7 py-5">
        <Container>
          <h4 className="banner-7-txt">Best Selling </h4>
          <h1 className="banner-7-txt">
            The best of the best, chosen by our clients
          </h1>

          <Row className="banner-7-combine">
            <Col xs={12} md={6} lg={3} sm={12} className="banner-7-col">
              <div className="banner-7-content p-2">
                <Col md={3}>
                  <div>
                    <img
                      className="banner-7-img"
                      src="/assets/img-1.png"
                      alt="img"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <p className="banner-7-txt-2">Print design</p>
                  <p className="banner-7-txt-2">
                    $ 12.55 &nbsp;
                    <span className="banner-7-txt-3">
                      <del>$ 20.55</del>
                    </span>
                  </p>

                  <Flex gap="middle" vertical>
                    <Rate
                      tooltips={desc}
                      className="rating"
                      onChange={setValue}
                      value={value}
                      disabled
                    />
                  </Flex>
                </Col>
                <Col md={3} className="banner-7-icon">
                  <div>
                    <FaCartShopping />
                  </div>
                </Col>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} sm={12} className="banner-7-col">
              <div className="banner-7-content p-2">
                <Col md={3}>
                  <div>
                    <img
                      className="banner-7-img"
                      src="/assets/img-2.png"
                      alt="img"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <p className="banner-7-txt-2">Print design</p>
                  <p className="banner-7-txt-2">
                    $ 12.55 &nbsp; &nbsp;
                    <span className="banner-7-txt-3">
                      <del>$ 20.55</del>
                    </span>
                  </p>

                  <Flex gap="middle" vertical>
                    <Rate
                      tooltips={desc}
                      className="rating"
                      onChange={setValue}
                      value={value}
                      disabled
                    />
                  </Flex>
                </Col>
                <Col md={3} className="banner-7-icon">
                  <div>
                    <FaCartShopping />
                  </div>
                </Col>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} sm={12} className="banner-7-col">
              <div className="banner-7-content p-2">
                <Col md={3}>
                  <div>
                    <img
                      className="banner-7-img"
                      src="/assets/img-3.png"
                      alt="img"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <p className="banner-7-txt-2">Print design</p>
                  <p className="banner-7-txt-2">
                    $ 12.55 &nbsp; &nbsp;
                    <span className="banner-7-txt-3">
                      <del>$ 20.55</del>
                    </span>
                  </p>

                  <Flex gap="middle" vertical>
                    <Rate
                      tooltips={desc}
                      className="rating"
                      onChange={setValue}
                      value={value}
                      disabled
                    />
                  </Flex>
                </Col>
                <Col md={3} className="banner-7-icon">
                  <div>
                    <FaCartShopping />
                  </div>
                </Col>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} sm={12} className="banner-7-col">
              <div className="banner-7-content p-2">
                <Col md={3}>
                  <div>
                    <img
                      className="banner-7-img"
                      src="/assets/img-1.png"
                      alt="img"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <p className="banner-7-txt-2">Print design</p>
                  <p className="banner-7-txt-2">
                    $ 12.55 &nbsp; &nbsp;
                    <span className="banner-7-txt-3">
                      <del>$ 20.55</del>
                    </span>
                  </p>

                  <Flex gap="middle" vertical>
                    <Rate
                      tooltips={desc}
                      className="rating"
                      onChange={setValue}
                      value={value}
                      disabled
                    />
                  </Flex>
                </Col>
                <Col md={3} className="banner-7-icon">
                  <div>
                    <FaCartShopping />
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default Banner;
